.ant-modal-close-x {
	justify-content: left !important;
}
.text-center {
	text-align: center;
}
.mt-4 {
	margin-top: 16px;
}
.mr-4 {
	margin-right: 16px;
}
.fx {
	// flexbox布局
	&-flexbox {
		width: auto;
		display: flex;
		align-items: center;

		&-flexwrap {
			flex-wrap: wrap;
		}

		// justify-content
		&-center {
			justify-content: center;
		}

		&-between {
			justify-content: space-between;
		}

		&-around {
			justify-content: space-around;
		}

		&-right {
			justify-content: flex-end;
		}

		// align-items
		&-align-initial {
			align-items: initial;
		}

		&-align-end {
			align-items: flex-end;
		}

		&-align-start {
			align-items: flex-start;
		}

		// align-content
		&-content-around {
			align-content: space-around;
		}

		&-vertical {
			flex-direction: column;
			height: 100%;

			.fx-flexbox-item {
				width: 100%;
			}
		}

		&-item {
			flex: 1;

			&-start {
				align-self: flex-start;
				justify-self: flex-end;
			}

			&-center {
				align-self: center;
			}

			&-end {
				align-self: flex-end;
			}
		}
	}
}

.fx__contnet-wrap {
	overflow-y: auto;

	& > div {
		height: 100%;
	}

	.ice-stark-loaded,
	#icestarkNode {
		height: 100%;
	}

	.ant-pro-layout-main .ant-tabs {
		margin-top: -10px;
	}
}
.fle-page-header {
	display: none;
}
.single-line {
	overflow: hidden; /* 隐藏溢出的内容 */
	white-space: nowrap; /* 不换行 */
	text-overflow: ellipsis; /* 显示省略符号来代表被裁剪的文本 */
}
.ellipsis {
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
