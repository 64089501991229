li {
  list-style-type: none;
}

p {
  margin: 0;
}

.ant-app {
  .ant-pro-layout {
  .ant-pro-layout-content {
    padding: 16px;
    background-color: transparent;
  }
  .ant-pro-page-container {
    background-color: #fff;
    border-radius: 2px;
  }}

  .ant-input-textarea-show-count {
    textarea.ant-input {
      padding-bottom: 22px;
    }
    .ant-input-data-count {
      bottom: 0;
      right: 4px;
    }
  }

  // 注意table统一border为false
  .ant-table > .ant-table-container {
    border-top: 1px solid rgb(238, 238, 238);
    border-left: 1px solid rgb(238, 238, 238);
    border-right: 1px solid rgb(238, 238, 238);
  }

  .ant-pro-query-filter,
  .ant-pro-card-body {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .ant-pro-query-filter.ant-pro-query-filter {
    padding-top: 0;
    padding-bottom: 0;
  }

  .fle-table-cell:empty:before {
    content: '-';
  }
}

@primary-color: #2c68ff;@link-color: #387FFD;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);