// @import '@/style/theme.less';

.peliten-pro-global-footer-copyright {
	font-size: 12px !important;
	color: rgba(119, 119, 119, 1) !important;
}

.peliten-pro-global-footer {
	margin-block-start: 36px;

	.peliten-pro-global-footer-list {
		.peliten-pro-global-footer-list-link {
			color: rgba(119, 119, 119, 1);
			font-size: 12px;

			&:hover {
				color: #000;
			}
		}
	}
}

.message-drawer-root {
	background: #e9effa !important;
	.ant-drawer-header {
		padding: 16px !important;
		.peliten-drawer-header-title {
			flex-direction: row-reverse;
			.peliten-drawer-close {
				padding: 0;
				margin: 0;
			}
		}
	}
	.ant-drawer-body {
		padding: 0 16px !important;
		position: relative;
		.tab-class {
			.ant-tabs-nav::before {
				border: #000;
			}
			.ant-tabs-content-holder {
				padding-top: 40px;
				// height: calc(100vh - 150px);
				// height: 400px;
			}
			.ant-tabs-tab {
				padding: 16px 0 4px;
			}
			.ant-tabs-tab-active {
				.ant-tabs-tab-btn {
					.ant-badge {
						color: #2c6bff;
					}
				}
			}
		}
		.has-read-box {
			position: absolute;
			right: 16px;
			top: 16px;
			font-size: 14px;
			font-weight: 400;
			color: #777777;
			cursor: pointer;
		}
		.message-tab-box {
			position: absolute;
			width: 100%;
			height: 40px;
			display: flex;
			top: 56px;
			left: 0;
			background-color: #e9effa;
			.message-tab {
				font-size: 12px;
				color: #aaaaaa;
				padding: 4px 16px;
				margin-left: 16px;
				line-height: 14px;
				cursor: pointer;
			}
			.message-tab-active {
				background: #ffffff;
				border-radius: 4px;
				height: 24px;
				color: #2c6bff;
			}
		}
	}
}

.message-box-root {
	width: 100%;
	min-height: 127px;
	box-sizing: border-box;
	padding: 16px;
	background-color: #fff;
	border-radius: 8px;
	margin-bottom: 8px;
	.message-app-name {
		font-size: 12px;
		color: #aaaaaa;
		margin-bottom: 4px;
	}
	.message-title-box {
		display: flex;
		justify-content: space-between;
		line-height: 22px;
		margin-bottom: 4px;
		.message-title {
			font-size: 16px;
			color: #333333;
			.red-dom {
				display: inline-block;
				width: 6px;
				height: 6px;
				background: #f42022;
				border-radius: 50%;
				margin: 0 0 7px 2px;
			}
		}
		.message-time {
			font-size: 12px;
			color: #aaaaaa;
		}
	}
	.message-content {
		margin-bottom: 8px;
		font-size: 14px;
		color: #aaaaaa;
	}
	.message-see-detail {
		font-size: 14px;
		color: #2c6bff;
		// float: right;
		cursor: pointer;
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}
}
.content-scroll {
	height: 600px;
}
.content-scroll::-webkit-scrollbar {
	width: 0px;
	height: 0px;
}
.message-no-data {
	width: 180px;
	height: 180px;
	margin: 150px auto;
	.no-data-img {
		width: 100%;
		height: 100%;
	}
}
.peliten-empty-image {
	display: none;
}
.peliten-empty-description {
	display: none;
}

@primary-color: #2c68ff;@link-color: #387FFD;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);