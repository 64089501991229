@import '@/style/vars.less';

// table
.fx__goods-sku-table,
.fx__goods-score-table {
  display: inline-block;

  table.form-table {
    border-collapse: collapse;
    border-spacing: 0;

    thead {
      display: table-header-group;
      vertical-align: middle;
      border-color: inherit;

      th {
        padding: 5px 10px;
      }
    }

    .field {
      position: relative;
      height: 100%;

      input {
        text-align: center;
        font-size: 12px;
        color: #333;
        cursor: auto;

        &:focus {
          outline: 1px solid @primary-color;
          outline-offset: 0;
        }

        &::placeholder {
          color: #ddd;
          font-weight: 400;
        }
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
      input[type="number"] {
        -moz-appearance: textfield;
      }

      .ant-input-number {
        border: none;
        width: 100%;
        height: 100%;
        display: block;

        &.ant-input-number:focus,
        &.ant-input-number-focused {
          box-shadow: none;
        }

        .ant-input-number-input-wrap {
          height: 100%;
        }
      }

      &.error {
        input {
          outline: 1px solid #f42d2d !important;
        }
      }

      .field-error {
        position: absolute;
        left: -1px;
        line-height: 1;
        z-index: 3;
        margin-top: 1px;
        padding: 2px 5px;
        color: #fff;
        // background: @primary-color;
        background: rgba(244, 45, 45, 0.8);
        // max-width: 100%;
        text-align: left;
        // white-space: normal;
      }
    }

    em.required {
      color: #ff4d4f;
      margin-right: 4px;
    }

    .ant-btn-sm {
      font-size: 12px;
      display: block;
      margin: 0 auto;
    }

    th,
    td {
      position: relative;
      min-width: 80px;
      max-width: 100px;
      font-weight: 400;
      line-height: 28px;
      // background: #f2f2f2;
      // white-space: nowrap;
      text-align: center;
      color: #333;
      border: 1px solid #ccc;
      font-size: 12px;
      padding: 0;
    }

    // td {
    //   height: 30px;
    // }
  }

  .image-border {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 90px;
    box-sizing: border-box;
    border: 1px dashed #ddd;
    border-radius: 4px;
  }
}
@primary-color: #2c68ff;@link-color: #387FFD;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);