.fle-tag-group {
	.fle-space-item {
		height: 20px;
	}

	.fle-tag {
		display: inline-block;
		margin: 0;
		max-width: 87px;
		border-radius: 2px;
		border: none;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		vertical-align: bottom;
	}
}

.fle-tag-group-tooltip.fle-tooltip .fle-tooltip-inner {
	padding: 12px;
	width: max-content;
	max-width: 312px;

	.fle-tag {
		max-width: 288px;
	}
}

@primary-color: #2c68ff;@link-color: #387FFD;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);