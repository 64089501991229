/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

.ant-app {
  
  .ant-pro-layout-content {
    padding: 16px;
    background-color: transparent;
  }
  .ant-pro-page-container {
    background-color: #fff;
    border-radius: 2px;

    .ant-tabs .ant-tabs-tab {
      padding-top: 0;
    }
  }

	.ant-image-mask-info {
		font-size: 12px;
	}

  .ant-pro-query-filter-actions {
    .ant-space-item:first-child > div {
      flex-direction: row-reverse;
    }
  }

  .ant-input-textarea-show-count {
    textarea.ant-input {
      padding-bottom: 22px;
    }
    .ant-input-data-count {
      bottom: 0;
      right: 4px;
    }
  }

  // 注意table统一border为false
  .ant-table > .ant-table-container {
    border-top: 1px solid rgb(238, 238, 238);
    border-left: 1px solid rgb(238, 238, 238);
    border-right: 1px solid rgb(238, 238, 238);
  }
}