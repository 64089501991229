@import '@/style';
.examine-loading {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.pl-20 {
	padding-left: 20px;
}

.mt-20 {
	margin-top: 20px;
}

.image-border {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 90px;
	height: 90px;
	box-sizing: border-box;
	border: 1px dashed #ddd;
	border-radius: 4px;
}

.fx__img-wrap {
	position: relative;
	height: 88px;
	border-radius: 4px;
	border: 1px dashed #d9d9d9;
	overflow: hidden;

	&:hover .action-wrap {
		display: flex;
	}

	.preview-img {
		img {
			height: 86px;
			padding: 2px;
		}

		.anticon {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate3d(-50%, -50%, 0);
			color: #fff;
			font-size: 28px;
			cursor: pointer;
		}
	}

	.action-wrap {
		position: absolute;
		top: 0;
		left: 0;
		display: none;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);

		.anticon {
			color: #fff;
			font-size: 16px;
			cursor: pointer;
		}
	}
}

.examine-details {
	font-size: 14px;
	height: 100%;
	color: #787878;

	.attr-background {
		width: 100%;
	}
	.title-warp {
		// width: 58%;
		word-wrap: break-word;
		word-break: break-all;
	}
	.weight-title {
		text-align: left;
		font-size: 14px;
		font-weight: 600;
		color: #262626;
		width: 100%;
	}
	.change-title {
		color: #f43636 !important;
	}

	.no-title {
		font-size: 14px;
		font-weight: 600;
		color: #262626;
	}

	.image-border-color {
		border: 3px solid #f42d2d;
	}

	&-detail {
		padding: 20px 20px 60px;
		background-color: #fff;

		video {
			width: 100%;
		}

		.detail-info {
			color: #2c68ff;
			font-size: 20px;
			font-weight: 600;
			text-align: center;
			margin-bottom: 24px;
		}
		.info-type {
			margin-left: 70px;
		}
		.info-basic {
			color: #262626;
			font-size: 16px;
			font-weight: 600;
			margin-top: 20px;
			margin-bottom: 16px;
		}
		.info-image {
			display: flex;
			width: 100%;
			.ant-space {
				width: 100%;
			}
			div {
				line-height: 80px;
			}
		}
		.title-name,
		.title-describe {
			display: flex;
			margin-bottom: 16px;
			width: 100%;
		}

		.title-attribute {
			display: flex;
			text-align: right;
			padding: 24px;
			// width: 58%;
			background-color: #ffffff;
			.attribute-space {
				width: 100% !important;
			}
		}
		.goods-detail {
			width: 100%;
			padding: 20px;
			background-color: #fafafa;
		}
		.attribute-title {
			padding-top: 20px;
			width: 160px;
			text-align: right;
		}

		.goods-attribute {
			display: flex;
		}

		.attribute-space {
			margin-right: 20px;
		}
		.image-title {
			width: 160px;
			text-align: right;
		}
		.goods-margin {
			margin-bottom: 16px;
		}
		.return-goods {
			width: 160px;
			text-align: right;
		}

		.goods-info {
			display: flex;
			width: 100%;
			.link-file {
				max-width: 300px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
			.goods-tag {
				display: flex;
				width: 100%;
				max-height: 500px;
				flex-wrap: wrap;
			}
			.goods-block {
				width: 130px;
			}
		}

		.attribute-table {
			width: 100%;
		}
	}
}

.title-margin {
	margin-right: 16px;
}

.examione-status-icon {
	font-size: 26px;
}

.temp_list_container {
	.temp_container {
		margin-bottom: 20px;
		border-radius: 2px;
		box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);

		.temp_head_container {
			line-height: 40px;
			padding: 0 10px 0 20px;
			display: flex;
			font-size: 12px;
			align-items: center;
			justify-content: space-between;
			border-bottom: @--style-solid-border;

			.head_info {
				display: flex;
				align-items: center;
				justify-content: flex-start;

				p {
					margin-right: 20px;
				}

				.temp_name {
					color: #000;
				}
			}

			.ant-btn-sm {
				font-size: 12px;
			}

			.temp_from,
			.temp_used,
			.temp_time {
				color: #9b9b9b;
				padding-right: 10px;
			}
		}

		.temp_content_container {
			.temp_content_title {
				padding: 0 20px;
				line-height: 40px;
				color: #333;
				font-weight: 500;
				background-color: @grey-bg-op1;
			}

			.temp_content_info {
				padding: 10px 20px;
				line-height: 32px;
				font-size: 13px;
				color: #333;
				border-top: @--style-solid-border;
			}
		}
	}
}

@primary-color: #2c68ff;@link-color: #387FFD;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);