
@import './reset.less';
@import './theme.less';
@import './global.less';
@import './extends.less';
@import './vars.less';

.ice-stark-loaded {
  padding-top: 50px;
}

.fx__filter-wrap {
  .ant-input-group.ant-input-group-compact {
    display: flex;
  }
  .fx__split {
    height: 32px;
    line-height: 32px;
    text-align: center;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    background-color: #fff;
  }
  .ant-input-number-input {
    text-align: center;
  }
}
.page_plustable__body{
  height: auto!important;
}
.detail-title{
  display: inline-block;
  border-bottom-width: 2px;
  border-style: solid;
  border-color: rgb(61,127,255);
  color: rgb(61,127,255);
  margin-bottom: 16px;
}